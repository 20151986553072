import { ref, computed, watchEffect } from "vue";
import { useLocalStorage } from "@vueuse/core";
import { Connection, PublicKey, Keypair, clusterApiUrl, SystemProgram } from "@solana/web3.js";
import { AnchorProvider, Program } from "@project-serum/anchor";
import { WalletMultiButton, useAnchorWallet } from "solana-wallets-vue";
import idl from "./idl.json";
const programID = new PublicKey(idl.metadata.address);
const preflightCommitment = "processed";
export default {
  components: {
    WalletMultiButton
  },
  setup() {
    const dark = ref(false);
    const wallet = useAnchorWallet();
    const connection = new Connection(clusterApiUrl("devnet"), preflightCommitment);
    const provider = computed(() => {
      if (!wallet.value) return;
      return new AnchorProvider(connection, wallet.value, {
        preflightCommitment
      });
    });
    const program = computed(() => {
      if (!provider.value) return;
      return new Program(idl, programID, provider.value);
    });
    const counterPublicKey = useLocalStorage("counterPublicKey", null);
    const counter = ref(0);
    watchEffect(async () => {
      if (!counterPublicKey.value || !program.value) return;
      const account = await program.value.account.baseAccount.fetch(counterPublicKey.value);
      counter.value = account.count.toNumber();
    });
    const createCounter = async () => {
      if (!wallet.value) {
        return alert("Connect your wallet first.");
      }
      const newCounter = Keypair.generate();
      await program.value.methods.create().accounts({
        baseAccount: newCounter.publicKey,
        user: wallet.value.publicKey,
        systemProgram: SystemProgram.programId
      }).signers([newCounter]).rpc();
      counterPublicKey.value = newCounter.publicKey;
    };
    const incrementCounter = async () => {
      if (!wallet.value) {
        return alert("Connect your wallet first.");
      } else if (!counterPublicKey.value) {
        return alert("Create a new counter first.");
      }
      await program.value.methods.increment().accounts({
        baseAccount: counterPublicKey.value
      }).rpc();
      counter.value += 1;
    };
    return {
      dark,
      counterPublicKey,
      counter,
      createCounter,
      incrementCounter
    };
  }
};